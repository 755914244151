import React, {useEffect, useState} from 'react';
import LoginTitle from "../../../components/molecules/auth/login_title";
import LoginForm from "../../../components/organisms/auth/login_form";
import api from '../../../api';

function LoginPage() {

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null);

  useEffect(() => {
    validateUser()
  }, [])

  const validateUser = async () => {
    const {response, error} = await api.user.detail()
    if(!error) {
      window.location.href = '/'
    }
  }

  const handleSubmit = async (data: any) => {
    setError(null)
    setLoading(true)
    // await api.auth.csrf()
    const {response, error} = await api.auth.logIn(data)
    if (!error) {
        var expires = "";
      // if (days) {
        var date = new Date();
        date.setTime(date.getTime() + response?.expires_in ?? 0);
        expires = "; expires=" + date.toUTCString();
      // }
      document.cookie = 'token-agent' + "=" + (response?.data?.access_token || "")  + expires + "; Path=/";

      await api.user.detail()
      window.location.reload();
    }
    else {
      setError(error?.message ? error.message : error)
    }
    setLoading(false)
  }

  return (
      <div className='login-page bg-gradient-to-br from-red-600 to-red-800 min-h-screen flex pt-28 justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
        <div className="max-w-md w-full space-y-8">
          <LoginTitle/>

          <div className="rounded bg-white max-w-md rounded overflow-hidden shadow-xl p-5">
            <LoginForm handleSubmit={handleSubmit} loading={loading} error={error}/>
          </div>
        </div>
      </div>
  );
}

export default LoginPage;