import React, {lazy, Suspense} from "react";
import HeaderLayout from "./components/organisms/layout/HeaderLayout";
import {Layout, Spin} from "antd";
import SiderLayout from "./components/organisms/layout/SiderLayout";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import GuardedRoute from "./pages/guardroute";
import {useAuth} from "./hooks/AuthContext";
import OutstandingPage from "./pages/report/outstanding";
import AccountFilterPage from "./pages/account/list/filter";

const AccountListPage = lazy(() => import("./pages/account/list"))
const SubAccountPage = lazy(() => import("./pages/account/sub"))
const JackpotSettingPage = lazy(() => import("./pages/setting/jackpot"))
const ApproveAccountPage = lazy(() => import("./pages/account/approve"))
const AccountAdminPage = lazy(() => import("./pages/account/admin"))

const NotFoundPage = lazy(() => import("./pages/not_found"))
const DashboardPage = lazy(() => import("./pages/dashboard"))
const JackpotPage = lazy(() => import("./pages/jackpot"))
const CashDetailPage = lazy(() => import("./pages/report/cash/detail"))
const WinLoseDetailPage = lazy(() => import("./pages/report/winlose/detail"))
const AccountGroupPage = lazy(() => import("./pages/account/group"))
const CashPage = lazy(() => import("./pages/report/cash"))
const ChangePasswordPage = lazy(() => import("./pages/user/change_password"))
const WinLosePage = lazy(() => import("./pages/report/winlose"))
const GameSettingPage = lazy(() => import("./pages/setting/game"))
const SystemSettingPage = lazy(() => import("./pages/setting/system"))
const LoginPage = lazy(() => import('./pages/auth/login'))
const LocationSettingPage = lazy(() => import('./pages/setting/location'));
const OnlinePage = lazy(() => import('./pages/online'));
const AccountTaxPage = lazy(() => import('./pages/tax/account'));
const ActivityPage = lazy(() => import("./pages/log/activity"))
const OnlineMemberPage = lazy(() => import("./pages/log/member_online"))
const WebIndex = () => {

  const {loading, logout, isAuthenticate, isSub, canAccount, canCash, isAdmin} = useAuth()

  if (loading) return <Spin className='flex h-screen justify-center items-center' size="large"></Spin>
  if (!isAuthenticate) return <LoginPage/>

  return (
      <BrowserRouter>
        <Layout style={{minHeight: '100vh', minWidth: 1600}}>
          <HeaderLayout/>
          <Layout>
            <SiderLayout handleLogout={logout}/>
            <Layout style={{ padding: '0 24px 24px' }}>
              {/*<Breadcrumb style={{ margin: '16px 0' }}>*/}
              {/*  <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
              {/*  <Breadcrumb.Item>List</Breadcrumb.Item>*/}
              {/*  <Breadcrumb.Item>App</Breadcrumb.Item>*/}
              {/*</Breadcrumb>*/}
              <Layout.Content className='mt-14'>
                <Suspense fallback={<Spin className='flex h-screen justify-center items-center' size="large"></Spin>}>
                  <Routes>
                    <Route path='/login' index element={<LoginPage/>}></Route>
                    <Route path='/' element={<GuardedRoute><DashboardPage/></GuardedRoute>}></Route>
                    <Route path='/jackpot' element={<GuardedRoute><JackpotPage/></GuardedRoute>}></Route>
                    <Route path='/online' element={<GuardedRoute><OnlinePage/></GuardedRoute>}></Route>
                    <Route path='/login' element={<LoginPage/>}></Route>
                    {
                      canAccount ? <Route path='/account/list' element={<GuardedRoute><AccountListPage/></GuardedRoute>}></Route> : null
                    }
                    {
                      canAccount ? <Route path='/account/list/filter/:userId' element={<GuardedRoute><AccountFilterPage/></GuardedRoute>}></Route>: null
                    }
                    {
                      !isSub ? <Route path='/account/sub' element={<GuardedRoute><SubAccountPage/></GuardedRoute>}></Route> : null
                    }

                    <Route path='/account/approve' element={<GuardedRoute><ApproveAccountPage/></GuardedRoute>}></Route>
                    <Route path='/account/group' element={<GuardedRoute><AccountGroupPage/></GuardedRoute>}></Route>

                    {
                      isAdmin ? <Route path='/account/admin' element={<GuardedRoute><AccountAdminPage/></GuardedRoute>}></Route> : null
                    }
                    {
                      canCash ? <Route path='/report/cash' element={<GuardedRoute><CashPage/></GuardedRoute>}></Route> : null
                    }

                    {
                      canCash ? <Route path='/report/cash' element={<GuardedRoute><CashPage/></GuardedRoute>}></Route> : null
                    }
                    {
                      canCash ? <Route path='/report/cash/:userId' element={<GuardedRoute><CashPage/></GuardedRoute>}></Route> : null
                    }

                    {
                      canCash ? <Route path='/report/cash/detail/:userId' element={<GuardedRoute><CashDetailPage/></GuardedRoute>}></Route> : null
                    }

                    {
                      canCash ? <Route path='/report/old_cash' element={<GuardedRoute><CashPage/></GuardedRoute>}></Route> : null
                    }

                    {
                      canCash ? <Route path='/report/old_cash' element={<GuardedRoute><CashPage/></GuardedRoute>}></Route> : null
                    }
                    {
                      canCash ? <Route path='/report/old_cash/:userId' element={<GuardedRoute><CashPage/></GuardedRoute>}></Route> : null
                    }

                    {
                      canCash ? <Route path='/report/old_cash/detail/:userId' element={<GuardedRoute><CashDetailPage/></GuardedRoute>}></Route> : null
                    }

                    <Route path='/report/outstanding/:userId' element={<GuardedRoute><OutstandingPage/></GuardedRoute>}></Route>

                    <Route path='/report/winlose' element={<GuardedRoute><WinLosePage/></GuardedRoute>}></Route>
                    <Route path='/report/winlose/:userId' element={<GuardedRoute><WinLosePage/></GuardedRoute>}></Route>
                    <Route path='/report/winlose/detail/:userId' element={<GuardedRoute><WinLoseDetailPage/></GuardedRoute>}></Route>

                    <Route path='/report/old_winlose' element={<GuardedRoute><WinLosePage/></GuardedRoute>}></Route>
                    <Route path='/report/old_winlose/:userId' element={<GuardedRoute><WinLosePage/></GuardedRoute>}></Route>
                    <Route path='/report/old_winlose/detail/:userId' element={<GuardedRoute><WinLoseDetailPage/></GuardedRoute>}></Route>

                    <Route path='/report/winlose/old' element={<GuardedRoute><WinLosePage/></GuardedRoute>}></Route>
                    <Route path='/report/winlose/old/:userId' element={<GuardedRoute><WinLosePage/></GuardedRoute>}></Route>
                    <Route path='/report/winlose/detail/old/:userId' element={<GuardedRoute><WinLoseDetailPage/></GuardedRoute>}></Route>

                    <Route path='/activity' element={<GuardedRoute><ActivityPage/></GuardedRoute>}></Route>
                    <Route path='/member_online' element={<GuardedRoute><OnlineMemberPage/></GuardedRoute>}></Route>

                    <Route path='/setting/game' element={<GuardedRoute><GameSettingPage/></GuardedRoute>}></Route>
                    <Route path='/setting/jackpot' element={<GuardedRoute><JackpotSettingPage/></GuardedRoute>}></Route>
                    <Route path='/setting/location' element={<GuardedRoute><LocationSettingPage/></GuardedRoute>}></Route>
                    <Route path='/setting/system' element={<GuardedRoute><SystemSettingPage/></GuardedRoute>}></Route>

                    {
                      isAdmin || (isAdmin && isSub) ? <Route path='/tax/account/filter' element={<GuardedRoute><AccountTaxPage/></GuardedRoute>}></Route> : null
                    }

                    <Route path='/user/change_password' element={<GuardedRoute><ChangePasswordPage/></GuardedRoute>}></Route>
                    <Route path='*' element={<GuardedRoute><NotFoundPage/></GuardedRoute>}></Route>
                  </Routes>
                </Suspense>
              </Layout.Content>
            </Layout>
          </Layout>
        </Layout>
      </BrowserRouter>
  )
}

export default WebIndex;