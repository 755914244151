import axiosInstance from "./axios";
import auth from './auth';
import user from './user';
import report from './report'
import setting from './setting'
import account from './account'
import admin from './admin'
import sub from './sub'
import approval from './approval'
import dashboard from "./dashboard";
import group from "./group";
import activity from "./activity";
import location from "./location";
import jackpot from "./jackpot";
import tax from "./tax";
import exp from "./export";

export default {
  auth: auth(axiosInstance, `/api`),
  user: user(axiosInstance, `/api/user`),
  jackpot: jackpot(axiosInstance, `/api/jackpot`),
  admin: admin(axiosInstance, `/api/admin`),
  group: group(axiosInstance, `/api/user_group`),
  dashboard: dashboard(axiosInstance, `/api/dashboard`),
  report: report(axiosInstance, `/api/report`),
  account: account(axiosInstance, `/api/account`),
  sub: sub(axiosInstance, `/api/account/sub`),
  location: location(axiosInstance, `/api/location`),
  setting: setting(axiosInstance, `/api/setting`),
  tax: tax(axiosInstance, `/api/tor`),
  approval: approval(axiosInstance, `/api/approval`),
  activity: activity(axiosInstance, `/api/activity`),
  export: exp(axiosInstance, `/api/export`)
}